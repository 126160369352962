.container {
    background: white;
    box-shadow: 0 15px 30px 0 #f7f7ff;
    font-size: 10px;
    padding: 2.5em;
    border-radius: 1em;
    min-width: 40em;
    height: 20em;
}

.header {
    display: flex;
    align-content: center;
}

.content {
    font-size: 1.7em;
    padding: 1em;
}

.avatar {
    background: #fec !important;
    color: tan !important;
    font-weight: 400;
    margin-right: 0.5em;
}

.rating {
    margin: 0.3em 0;
}
