.visitorFormContainer {
    background-color: #ffeecc;
    min-height: 100vh;
}

.visitorHeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.visitorHeaderLogo {
    height: 90px;
    width: 90px;
    padding: 10px;
}

.visitorHeaderTitle {
    color: #fa824c;
    margin-block: 0;
}
