.container {
    margin: 100px 0 50px;
    font-size: 10px;
}

@media screen and (min-width: 1440px) {
    .container {
        font-size: 12px;
    }
}

.groupHeader {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-between;
    padding: 0 10px;
}

.groupHeaderTitle {
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.groupLink {
    font-size: 1.6em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: right;
    color: orange;
}

.subTitle {
    font-size: 1.35em;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    margin-top: -0.8em;
    margin-bottom: 3em;
}
