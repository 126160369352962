.nav {
    padding: 20px;
}

.linkContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.link {
    color: #000;
}

.linkItem {
    width: 15rem;
    margin: 0.5rem;
}

.hideOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.footerNav {
    font-size: 14px;
    display: flex;
    background: #fa824c;
    padding: 3rem;
    margin-top: 3rem;
}
