#header {
    font-size: 25px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-bottom: 5px;
}

#modal {
    border-radius: 2px;
    border-radius: 20px;
    max-width: 350px;
}

#content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
