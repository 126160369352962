.container {
    font-size: 10px;
}

.content {
    background: white;
    padding: 3em;
    height: 100%;
}

@media screen and (min-width: 1024px) {
    .container {
        font-size: 12px;
    }
}

@media screen and (min-width: 700px) {
    .container {
        font-size: 10px;
    }
}

.image {
    width: 100%;
    border-radius: 5px;
}

.header {
    margin-top: 40px;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.aboutContent {
    margin-top: 30px;
    font-size: 1.5em;
    font-style: normal;
    line-height: 25px;
    text-align: left;
    font-weight: 300;
}

.address {
    font-weight: 100;
    color: #a1a1a1;
    font-size: 0.8em;
    font-style: normal;
    letter-spacing: 0.5px;
    margin-top: 20px;
    line-height: 22px;
    text-align: left;
}
