.card {
    text-align: left;
}

.subtext {
    color: grey;
    font-size: 12px;
    margin: 2px;
    text-align: right;
}

.input {
    margin-bottom: 10px;
}
