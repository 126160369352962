@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap);
body {
    font-family: 'Outfit', sans-serif;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.RentalCard_card__1rKDr {
    text-align: left;
}

.RentalCard_subtext__2O2cG {
    color: grey;
    font-size: 12px;
    margin: 2px;
    text-align: right;
}

.RentalCard_input__3Kcbj {
    margin-bottom: 10px;
}

.FormFields_fieldName__3XvCS {
    text-transform: uppercase !important;
    letter-spacing: 2px;
    color: #333333;
}

.FormFields_fieldInput__IhKxY {
    font-size: 40px;
    margin-top: 10px;
    height: 40px;
    padding: 10px 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #f2f2f2;
    font-family: 'Lato', sans-serif;
    color: #333;
}

.FormFields_dateNotification__252hh {
    text-align: center;
    margin-bottom: 5px !important;
}

.FormFields_dateField__3Nugq {
    width: 248px !important;
}

.FormFields_checkbox__l5J4P {
    margin: 0px 10px 10px 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px !important;
}

.FormFields_complexField__1mQfi {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 4px;
}

.FormFields_orangeLoader__iuVzC {
    margin: 2rem 0 !important;
}

.FormFields_orangeLoader__iuVzC::after {
    border-color: #fa824c transparent transparent !important;
}

.HourlyRental_card__35hN7 {
    text-align: left;
}

.HourlyRental_subtext__6ri_6 {
    color: grey;
    font-size: 12px;
    margin: 2px;
    text-align: right;
}

.HourlyRental_input__2dQ11 {
    margin-bottom: 10px;
}

.HourlyRental_fieldName__rLoiP {
    text-transform: uppercase !important;
    letter-spacing: 2px;
    color: #333333;
}

.LongTermRental_card__iESs8 {
    text-align: left;
}

.LongTermRental_subtext__14JRp {
    color: grey;
    font-size: 12px;
    margin: 2px;
    text-align: right;
}

.LongTermRental_input__20YRS {
    margin-bottom: 10px;
}

.LongTermRental_fieldName__2r5sv {
    text-transform: uppercase !important;
    letter-spacing: 2px;
    color: #333333;
}

#CarModal_header__dgXa0 {
    font-size: 25px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-bottom: 5px;
}

#CarModal_modal__pq0-Z {
    border-radius: 2px;
    border-radius: 20px;
    max-width: 350px;
}

#CarModal_content__3zHOQ {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.Footer_center__3coVf {
    text-align: center;
}

.SpotInfo_cardImage__1Fubq {
    object-fit: cover;
    width: 100% !important;
    height: 200px !important;
}

.SpotInfo_cardImagePreview__tAkKE {
    object-fit: cover;
    width: 100px !important;
    height: 100px !important;
}

.SpotInfo_whiteBackground__MIvc_ {
    width: 100vw;
    height: 100vh;
    background-color: white !important;
}

/* Card Styling */
.SpotInfo_card__1lwkk {
    border: 1px solid #e0e0e0 !important; /* Light grey border for the card */
    border-radius: 8px !important; /* Rounded corners */
    padding: 4px !important; /* Internal padding */
    margin: 8px !important;
    width: 90% !important;
    background-color: #ffffff !important; /* White background */
}

/* Card Header Styling */
.SpotInfo_cardHeader__2FlqX {
    font-size: 18px !important; /* Slightly larger font for header */
    font-family: 'Outfit' !important;
    font-weight: bold !important; /* Bold text */
    color: #333333 !important; /* Dark grey for header text */
    margin-bottom: 8px !important; /* Space below the header */
}

/* Card Meta Styling */
.SpotInfo_cardMeta__1dtmS {
    font-size: 14px !important; /* Smaller font for meta info */
    color: #888888 !important; /* Light grey for secondary text */
    margin-bottom: 4px !important; /* Space below meta info */
    font-family: 'Outfit' !important;
}

/* Card Description Styling */
.SpotInfo_cardDescription__30Tbz {
    font-size: 16px !important; /* Medium font for description */
    color: #555555 !important; /* Slightly darker grey */
    margin-bottom: 4px !important; /* Space below the description */
}

.SpotInfo_listItems__lW5dc {
    color: #b0b7d4;
    font-weight: 800;
    margin-top: 10px !important;
}

.SpotInfo_link__gup2h {
    color: #6c60fa;
}

.SpotInfo_subtitle__DsoUi {
    color: #aea6b9 !important;
    letter-spacing: 3px;
    font-size: 13px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 5px;
}

.SpotInfo_title__vv0H6 {
    font-size: 40px;
    font-weight: 800;
    margin-top: 5px;
}

.SpotInfo_bodyText__P80SQ {
    color: #aea6b9 !important;
    font-size: 16px;
}

.SpotInfo_checkbox__1XaiU {
    background-color: #fff !important;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    position: absolute;
    margin: 10px;
    border: 1px solid #ddd;
}

/* Book Now Button Styling */
.SpotInfo_bookNowButton__22XBX {
    background-color: #ee5800 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    color: white !important;
    font-family: 'Outfit' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 10px 0px !important;
    border-radius: 8px !important;
    text-align: center; /* Center text */
    max-width: 250px !important;
    min-width: 150px !important;
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

@media (max-width: 600px) {
    .SpotInfo_bookNowButton__22XBX {
        margin-top: 10px !important; /* Maintain spacing on mobile */
    }
}

/* Ensure parent container allows full width */
.SpotInfo_horizontalContainer__Ai_RC {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; /* Ensure container takes full width */
}

.SpotInfo_buttonContainer__14apK {
    display: flex;
    flex-direction: row; /* Default: Place buttons side by side */
    align-items: center; /* Vertically align */
    justify-content: space-between; /* Horizontally center */
    grid-gap: 12px;
    gap: 12px; /* Space between buttons */
    width: 100%;
    flex-wrap: wrap; /* Allows wrapping if needed */
}

/* Ensure buttons have consistent sizing */
.SpotInfo_moreInfoButton__2SAWA,
.SpotInfo_bookNowButton__22XBX {
    width: auto; /* Prevents full width */
    min-width: 150px; /* Ensures consistency */
    text-align: center;
}

/* Responsive breakpoint for mobile */
@media (max-width: 600px) {
    .SpotInfo_buttonContainer__14apK {
        flex-direction: column; /* Stack buttons on smaller screens */
        align-items: center; /* Center them */
    }

    .SpotInfo_moreInfoButton__2SAWA,
    .SpotInfo_bookNowButton__22XBX {
        width: 100% !important; /* Make buttons full width on mobile */
    }
}
/* Responsive breakpoint for mobile */
@media (max-width: 600px) {
    .SpotInfo_bookNowButton__22XBX {
        width: 100% !important;
    }
    .SpotInfo_moreInfoButton__2SAWA {
        width: 100% !important;
    }
}

.SpotInfo_cardRating__172wV {
    color: grey !important;
}

.SpotInfo_moreInfoButton__2SAWA {
    background-color: white !important;
    color: #707070 !important;
    border: #707070 1px solid !important;
    font-family: 'Outfit' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 10px 0px !important;
    border-radius: 8px !important;
    text-align: center; /* Center text */
    max-width: 220px !important;
    min-width: 150px !important;
    min-height: 40px !important;
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    margin: 1px !important;
}

/* Book Now Button Hover Effect */
.SpotInfo_bookNowButton__22XBX:hover {
    background-color: #f5f5f5; /* Light grey on hover */
    color: #000000; /* Black text on hover */
    border-color: #c0c0c0; /* Darker grey border on hover */
}

.SpotInfo_priceMarker__28wHv {
    border: 1px solid #d2d2d2;
    padding: 10px;
    border-radius: 20px;
    background-color: #fff !important;
    font-size: 20px;
    font-family: Lato, sans-serif;
}

/* Flex container for price and button */
.SpotInfo_priceAndButtonWrapper__88mf4 {
    display: flex;
    justify-content: space-between; /* Distribute price and button */
    align-items: center; /* Vertically center the elements */
    padding: 10px 0; /* Add spacing */
}

/* Price styling */
.SpotInfo_price__3ifg2 {
    font-size: 16px; /* Slightly larger font */
    font-weight: bold; /* Bold text */
    color: #666; /* Grey text for the price */
    font-family: 'Outfit' !important;
}

.SpotInfo_priceMarker__28wHv:hover {
    cursor: pointer;
}

.SpotInfo_priceText__1BOHK {
    text-align: center;
    padding-top: 10px;
    color: #b0b7d4;
    font-size: 20px;
}

.SpotInfo_sidebar__nRJ47 {
    max-height: 90vh;
    overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
    width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
    margin-left: 10px;
    background: #fff !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #f0f0f8 !important;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #dfdfe6 !important;
}

.SpotInfo_rateContainer__31v7b {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.SpotInfo_title__vv0H6 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.SpotInfo_rateOptions__3qeoz {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: left;
}

.SpotInfo_rateCard__jpLZx {
    cursor: pointer;
    font-family: 'Outfit';
    border: 2px solid #ccc !important;
    border-radius: 12px !important;
    padding: 0px !important;
    margin: 0px !important;
    transition: all 0.3s ease;
    flex: 1 1; /* Distributes available space */
    max-width: 250px !important;
}

.SpotInfo_rateCard__jpLZx:hover {
    border-color: #ff6600;
}

.SpotInfo_selected__T5adq {
    border-color: #ff6600 !important;
    box-shadow: 0px 0px 8px rgba(255, 102, 0, 0.3);
}

.SpotInfo_rateHeader__GT_XV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #333;
}

.SpotInfo_rateTitle__2ELkP {
    font-size: 16px;
}

.SpotInfo_ratePrice__2G9CY {
    font-size: 14px;
    color: #555;
    margin-top: 6px;
}

.SpotInfo_rateMax__2CeAs {
    font-size: 12px;
    color: #777;
    margin-top: 2px;
}

.Carousel_carousel__1FmzQ {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.Carousel_carousel__1FmzQ::-webkit-scrollbar {
    display: none;
}

/*@media screen and (min-width: 1024px) {*/
/*    .carousel {*/
/*        overflow-x: hidden;*/
/*    }*/

/*}*/

.Amenities_amenityPaper__2Y_ra {
    margin: 1em;
}

.DestinationMobile_container__3P_7A {
    background: #fbfbfb;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: Lato, sans-serif;
    margin: 0 auto;
    font-size: 10px;
}

.DestinationMobile_nav__2IrAn {
    padding: 20px;
}

.DestinationMobile_heroHeader__3go3s {
    margin: 50px 20px 20px;
}

.DestinationMobile_header__1Et-I {
    text-align: center;
}

.DestinationMobile_searchSection__3wb1i {
    padding: 0 20px;
}

.DestinationMobile_searchItem__1e3qJ {
    margin: 30px 0;
}

.DestinationMobile_spotsGroup__2dmBO {
    /*margin: 0 auto;*/
}

.DestinationMobile_searchSectionWeb__3NKA7 {
    border-radius: 6px;
    padding: 24px;
    background: white !important;
    box-shadow: 0 15px 30px 0 #fa824c33 !important;
    width: 400px;
    position: absolute;
    margin: 50px;
    z-index: 99;
}

.DestinationMobile_searchLabel__1MWaV {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #717171;
}

.DestinationMobile_dropdown__XqK7- {
    height: 45px;
    border-radius: 6px;
    width: 100%;
    box-shadow: none;
    font-family: Lato, sans-serif;
    background: #f2f2f2 !important;
    border: 0 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 43px;
}

.DestinationMobile_searchButton__V0URu {
    height: 54px;
}

.DestinationMobile_coverImageSection__2rUzY {
    margin-top: 10rem;
    position: relative;
}

.DestinationMobile_coverImageTextContainer__h176C {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 80px;
}

.DestinationMobile_coverImageText__3fVLv {
    color: #ffbc9f;
    font-size: 1.8em;
    text-align: center;
}

.DestinationMobile_coverImageHeaderText__3FTd2 {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: orange;
    color: transparent;
    font-size: 8em;
    margin: 0;
    text-align: center;
}

.DestinationSpotMap_map__1D6_x {
    border-radius: 6px;
    height: 330px;
    margin: 2em 1em;
}

.SpotInfo_container__1Lq50 {
    /*padding: 20px;*/
    padding: 15px 20px 40px;
    font-family: Helvetica Neue, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Helvetica, sans-serif;
    background: white;
}

.SpotInfo_headerSection__zmCWy {
    display: flex;
    width: 100%;
}

.SpotInfo_image__2izoT {
    width: 140px;
    height: 120px;
    border-radius: 3px;
    margin-right: 10px;
}

.SpotInfo_name__1Bym7 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #717171;
}

.SpotInfo_address__24Unv {
    font-size: 12px;
    color: grey !important;
}

.SpotInfo_close__3uErp {
    margin: 15px 10px;
    float: right;
}

.DestinationMobileItem_container__1hmDG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.8em 1.2em;
    width: 22em;
    position: relative;
}

.DestinationMobileItem_spotImage__2-3yS {
    border-radius: 6px 6px 0 0;
    height: 15em;
    width: 22em;
}

.DestinationMobileItem_cardContent__1YFaK {
    width: 100%;
    padding: 1em;
    border-radius: 0 0 6px 6px;
    background: white;
}

.DestinationMobileItem_spotName__jSpaU {
    font-family: Lato, sans-serif;
    font-size: 1.6em;
    font-style: normal;
    color: #000;
    margin: 0.5em 0;
    font-weight: 600;
}

.DestinationMobileItem_hideOverflow__2c3Ar {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DestinationMobileItem_hideOverflow__2c3Ar:hover {
    overflow: visible;
    white-space: normal;
}

.DestinationMobileItem_spotPrice__2tcUm {
    font-size: 1.6em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: right;
    color: #000;
}

.DestinationMobileItem_spotPriceInterval__3rfEG {
    color: #b0b7d4;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
}

.DestinationMobileItem_spotAddress__3q67e {
    font-family: Lato, sans-serif;
    font-size: 1.2em;
    color: #a1a1a1;
}

.DestinationMobileItem_spotFooter__2TyTZ {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1em;
}

.DestinationMobileItem_label__2Dxbw {
    position: absolute;
    top: 1em;
    left: 1em;
}

.DestinationMobileGroup_container__o7ZJP {
    margin: 100px 0 50px;
    font-size: 10px;
}

@media screen and (min-width: 1440px) {
    .DestinationMobileGroup_container__o7ZJP {
        font-size: 12px;
    }
}

.DestinationMobileGroup_groupHeader__1Pw7y {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-between;
    padding: 0 10px;
}

.DestinationMobileGroup_groupHeaderTitle__3inzR {
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.DestinationMobileGroup_groupLink__38V4T {
    font-size: 1.6em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: right;
    color: orange;
}

.DestinationMobileGroup_subTitle__2QRT6 {
    font-size: 1.35em;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    margin-top: -0.8em;
    margin-bottom: 3em;
}

.AboutDestinationMobile_container__2EI9n {
    font-size: 10px;
}

.AboutDestinationMobile_content__1WUWe {
    background: white;
    padding: 3em;
    height: 100%;
}

@media screen and (min-width: 1024px) {
    .AboutDestinationMobile_container__2EI9n {
        font-size: 12px;
    }
}

@media screen and (min-width: 700px) {
    .AboutDestinationMobile_container__2EI9n {
        font-size: 10px;
    }
}

.AboutDestinationMobile_image__IzyeE {
    width: 100%;
    border-radius: 5px;
}

.AboutDestinationMobile_header__UWNdt {
    margin-top: 40px;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.AboutDestinationMobile_aboutContent__3fr-b {
    margin-top: 30px;
    font-size: 1.5em;
    font-style: normal;
    line-height: 25px;
    text-align: left;
    font-weight: 300;
}

.AboutDestinationMobile_address__1UXyO {
    font-weight: 100;
    color: #a1a1a1;
    font-size: 0.8em;
    font-style: normal;
    letter-spacing: 0.5px;
    margin-top: 20px;
    line-height: 22px;
    text-align: left;
}

.OtherDestinations_container__2ZerN {
    margin: 0 15px;
}
.OtherDestinations_header__3ZyOg {
    text-align: center;
    color: orange;
}

.OtherDestinations_itemContainer__aYB-o {
    margin: 15px;
    display: flex;
    flex-direction: column;
    width: 120px;
}

.OtherDestinations_itemImage__2I-t1 {
    border-radius: 5px;
    height: 100px;
    width: 120px;
}

.OtherDestinations_itemHeader__26CJN {
    font-size: 14px;
    font-style: normal;
    line-height: 28px;
    font-weight: 400;
    text-align: left;
    color: #333333;
    margin-top: 10px;
}

.AboutAirgarageSection_container__2hkYu {
    padding: 2em;
    border-radius: 20px !important;
    margin: 3em 0;
}

.AboutAirgarageSection_airGarageImage__2N1MN {
    border-radius: 20px !important;
    margin: auto;
}

.AboutAirgarageSection_aboutAirGarageContent__3fgAa {
    font-size: 1.6em;
    text-align: left;
    margin: 1.5em 0 2.5em !important;
}

.AboutAirgarageSection_statsText__2b5bc {
    font-size: 1.6em;
    text-align: center;
}

.FAQ_root__2u_Jj {
    padding: 0 20px 8em;
    font-size: 10px;
}

.FAQ_faq__2PiKC {
    border-radius: 6px !important;
    border: none !important;
    background: white;
}

.FAQ_faq__2PiKC:before {
    content: none !important;
}

.FAQ_header__33OmR {
    font-size: 1.7em;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.5px;
    text-align: left;
}

.FAQ_content__t_SL7 {
    font-size: 1.5em;
    font-weight: 400;
}

.Reviews_container__3FdaL {
    background: white;
    box-shadow: 0 15px 30px 0 #f7f7ff;
    font-size: 10px;
    padding: 2.5em;
    border-radius: 1em;
    min-width: 40em;
    height: 20em;
}

.Reviews_header__2U7-3 {
    display: flex;
    align-content: center;
}

.Reviews_content__10p3n {
    font-size: 1.7em;
    padding: 1em;
}

.Reviews_avatar__1Qrl- {
    background: #fec !important;
    color: tan !important;
    font-weight: 400;
    margin-right: 0.5em;
}

.Reviews_rating__KO9xq {
    margin: 0.3em 0;
}

.Footer_container__2aVdY {
    background: #fa824c;
    width: 100vw;
    height: 400px;
    margin-top: 10rem;
    color: white;
    padding: 4em 5em;
}

.Footer_listContainer__3RmZD {
    margin: 2em;
}

.Footer_list__1Lmtn {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 200px;
}

.Footer_header__2hDlZ {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.Footer_content__1vTQx {
    margin: 8px 0;
    font-size: 15px;
    font-weight: 400;
    color: white;
    max-width: 200px;
    max-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Footer_content__1vTQx:hover {
    color: wheat;
}

.Footer_footerNav__3_qfZ {
    font-size: 14px;
    display: flex;
    margin: 25px 0;
}

.Footer_footerLogo__1ibjE {
    height: 35px;
    display: inline;
}

.ValidationCodeField_validateCodeContainer__zi72v {
    margin-bottom: 14px;
}

.ValidationCodeField_retrieveCodeMessage__1OwcI {
    font-size: 13px;
    color: #cacaca;
}

.VisitorForm_visitorFormContainer__n6Pnb {
    background-color: #ffeecc;
    min-height: 100vh;
}

.VisitorForm_visitorHeaderContainer__1BK1H {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VisitorForm_visitorHeaderLogo__2s-ZJ {
    height: 90px;
    width: 90px;
    padding: 10px;
}

.VisitorForm_visitorHeaderTitle__DWrbl {
    color: #fa824c;
    margin-block: 0;
}

.SiteMap_nav__3JJQq {
    padding: 20px;
}

.SiteMap_linkContainer__3kTj9 {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.SiteMap_link__1FSEo {
    color: #000;
}

.SiteMap_linkItem__3UJJu {
    width: 15rem;
    margin: 0.5rem;
}

.SiteMap_hideOverflow__1Qe_b {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.SiteMap_footerNav__3c4DJ {
    font-size: 14px;
    display: flex;
    background: #fa824c;
    padding: 3rem;
    margin-top: 3rem;
}

.Webpay_orangeLoader__3V-e1 {
    margin: 2rem 0 !important;
}

.Webpay_orangeLoader__3V-e1::after {
    border-color: #fa824c transparent transparent !important;
}

.ExpiredLinkCard_wrapper__12NDT {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
}

.ExpiredLinkCard_cardWrapper__1I5fq {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ExpiredLinkCard_text__qXUPc {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: white;
}

