.fieldName {
    text-transform: uppercase !important;
    letter-spacing: 2px;
    color: #333333;
}

.fieldInput {
    font-size: 40px;
    margin-top: 10px;
    height: 40px;
    padding: 10px 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #f2f2f2;
    font-family: 'Lato', sans-serif;
    color: #333;
}

.dateNotification {
    text-align: center;
    margin-bottom: 5px !important;
}

.dateField {
    width: 248px !important;
}

.checkbox {
    margin: 0px 10px 10px 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px !important;
}

.complexField {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 4px;
}

.orangeLoader {
    margin: 2rem 0 !important;
}

.orangeLoader::after {
    border-color: #fa824c transparent transparent !important;
}
