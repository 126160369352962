.root {
    padding: 0 20px 8em;
    font-size: 10px;
}

.faq {
    border-radius: 6px !important;
    border: none !important;
    background: white;
}

.faq:before {
    content: none !important;
}

.header {
    font-size: 1.7em;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.5px;
    text-align: left;
}

.content {
    font-size: 1.5em;
    font-weight: 400;
}
