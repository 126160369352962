.container {
    background: #fa824c;
    width: 100vw;
    height: 400px;
    margin-top: 10rem;
    color: white;
    padding: 4em 5em;
}

.listContainer {
    margin: 2em;
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 200px;
}

.header {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.content {
    margin: 8px 0;
    font-size: 15px;
    font-weight: 400;
    color: white;
    max-width: 200px;
    max-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content:hover {
    color: wheat;
}

.footerNav {
    font-size: 14px;
    display: flex;
    margin: 25px 0;
}

.footerLogo {
    height: 35px;
    display: inline;
}
