.carousel {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.carousel::-webkit-scrollbar {
    display: none;
}

/*@media screen and (min-width: 1024px) {*/
/*    .carousel {*/
/*        overflow-x: hidden;*/
/*    }*/

/*}*/
