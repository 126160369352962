.wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
}

.cardWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: white;
}
