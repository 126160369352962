.container {
    margin: 0 15px;
}
.header {
    text-align: center;
    color: orange;
}

.itemContainer {
    margin: 15px;
    display: flex;
    flex-direction: column;
    width: 120px;
}

.itemImage {
    border-radius: 5px;
    height: 100px;
    width: 120px;
}

.itemHeader {
    font-size: 14px;
    font-style: normal;
    line-height: 28px;
    font-weight: 400;
    text-align: left;
    color: #333333;
    margin-top: 10px;
}
