.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.8em 1.2em;
    width: 22em;
    position: relative;
}

.spotImage {
    border-radius: 6px 6px 0 0;
    height: 15em;
    width: 22em;
}

.cardContent {
    width: 100%;
    padding: 1em;
    border-radius: 0 0 6px 6px;
    background: white;
}

.spotName {
    font-family: Lato, sans-serif;
    font-size: 1.6em;
    font-style: normal;
    color: #000;
    margin: 0.5em 0;
    font-weight: 600;
}

.hideOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hideOverflow:hover {
    overflow: visible;
    white-space: normal;
}

.spotPrice {
    font-size: 1.6em;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: right;
    color: #000;
}

.spotPriceInterval {
    color: #b0b7d4;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
}

.spotAddress {
    font-family: Lato, sans-serif;
    font-size: 1.2em;
    color: #a1a1a1;
}

.spotFooter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1em;
}

.label {
    position: absolute;
    top: 1em;
    left: 1em;
}
