.container {
    /*padding: 20px;*/
    padding: 15px 20px 40px;
    font-family: Helvetica Neue, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Helvetica, sans-serif;
    background: white;
}

.headerSection {
    display: flex;
    width: 100%;
}

.image {
    width: 140px;
    height: 120px;
    border-radius: 3px;
    margin-right: 10px;
}

.name {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #717171;
}

.address {
    font-size: 12px;
    color: grey !important;
}

.close {
    margin: 15px 10px;
    float: right;
}
