.container {
    background: #fbfbfb;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: Lato, sans-serif;
    margin: 0 auto;
    font-size: 10px;
}

.nav {
    padding: 20px;
}

.heroHeader {
    margin: 50px 20px 20px;
}

.header {
    text-align: center;
}

.searchSection {
    padding: 0 20px;
}

.searchItem {
    margin: 30px 0;
}

.spotsGroup {
    /*margin: 0 auto;*/
}

.searchSectionWeb {
    border-radius: 6px;
    padding: 24px;
    background: white !important;
    box-shadow: 0 15px 30px 0 #fa824c33 !important;
    width: 400px;
    position: absolute;
    margin: 50px;
    z-index: 99;
}

.searchLabel {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #717171;
}

.dropdown {
    height: 45px;
    border-radius: 6px;
    width: 100%;
    box-shadow: none;
    font-family: Lato, sans-serif;
    background: #f2f2f2 !important;
    border: 0 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 43px;
}

.searchButton {
    height: 54px;
}

.coverImageSection {
    margin-top: 10rem;
    position: relative;
}

.coverImageTextContainer {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 80px;
}

.coverImageText {
    color: #ffbc9f;
    font-size: 1.8em;
    text-align: center;
}

.coverImageHeaderText {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: orange;
    color: transparent;
    font-size: 8em;
    margin: 0;
    text-align: center;
}
