.container {
    padding: 2em;
    border-radius: 20px !important;
    margin: 3em 0;
}

.airGarageImage {
    border-radius: 20px !important;
    margin: auto;
}

.aboutAirGarageContent {
    font-size: 1.6em;
    text-align: left;
    margin: 1.5em 0 2.5em !important;
}

.statsText {
    font-size: 1.6em;
    text-align: center;
}
